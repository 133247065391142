<template>
	<div class="mtiporesolucionFormHeader puiformheader">
		<v-row>
			<v-col cols="2">
				<pui-field :label="$t('header.mtiporesolucion.idtipres')" :value="getIdtipresValue"></pui-field>
			</v-col>
			<v-col cols="2">
				<pui-field :label="$t('header.mtiporesolucion.codresedi')" :value="getCodresediValue"></pui-field>
			</v-col>
			<v-col cols="8">
				<pui-field :label="$t('header.mtiporesolucion.name')" :value="getNameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mtiporesolucion-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mtiporesolucion'
		};
	},
	computed: {
		getIdtipresValue() {
			return this.model && this.model.idtipres ? this.model.idtipres : '-';
		},
		getCodresediValue() {
			return this.model && this.model.codresedi ? this.model.codresedi : '-';
		},
		getNameValue() {
			return this.model && this.model.name ? this.model.name : '-';
		},
	}
}
</script>
